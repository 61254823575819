<template>
  <div>
    <h4>Listado de prioridad de solicitud</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">

      <table class="table table-condensed">
        <thead>
          <th>Prioridad de solicitud</th>
          <th>Días para atención</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id_solicitud">
          <td>{{ elemento.prioridad }}</td>
          <td>{{ elemento.dias }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la prioridad de solicitud</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Prioridad de solicitud</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model="actual.prioridad"
            class="form-control"
            :class="estatus($v.actual.prioridad)"
          />
          <div class="errores" v-if="!$v.actual.prioridad.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Días para la atención</label>
          <input
            maxlength="4"
            style="max-width: 500px"
            v-model="actual.dias"
            class="form-control"
            :class="estatus($v.actual.dias)"
          />
          <div class="errores" v-if="!$v.actual.dias.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.dias.numeroValido">Incorrecto</div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar prioridad de solicitud",
      listado: [],
      usuarios: [],
      buscando: false,
      actual: {prioridad: null, dias: null},
    };
  },
  validations() {
    return {
      actual: {
        prioridad: {
          required,
        },
        dias: {
          required,
          numeroValido,
        }
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar prioridad de solicitud";
      this.actual = {prioridad: null, dias: null};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo = "Editar prioridad de solicitud",
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar la prioridad de solicitud: "+elemento.prioridad)) {
        api.eliminarPrioridad(elemento.id_prioridad)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaPrioridades();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar "+error);});
      }
    },
    guardar() {
      this.$v.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarPrioridad(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaPrioridades();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
        this.listado = [];
        this.buscando = false;
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaPrioridades() {
      api.listarPrioridades().then((r) => {
        this.listado = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaPrioridades();
  },
};
function numeroValido(numero) {
  console.log(numero);
    if (typeof numero == "undefined") {
        return true;
    }
    if (numero == null) return true;
    var num = ""+numero;
    var re = RegExp(/^(\d+)$/g);
    var validado = num.match(re) != null;
    console.log(validado);
    return validado;
}
</script>

