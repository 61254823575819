<template>
  <div>
    <h4>Registro de solicitud</h4>
    <p class="text-justify">
      <b>Proporciona los datos de tu solicitud.</b> Si deseas conocer el estatus de una solicitud da clic 
       <b-link to="/estatus">aquí</b-link>.
    </p>

    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Fecha de solicitud (aaaa-mm-dd)</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.fecha"
          class="form-control"
        />
        <div class="errores" v-if="!$v.solicitud.fecha.required">
          Requerido
        </div>
        <div class="errores" v-if="!$v.solicitud.fecha.fechaValida">
          Fecha incorrecta use el formato aaaa-mm-dd (ej. 1984-07-22)
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="base">Base de datos</label>
        <select
          v-model="solicitud.bd"
          :class="estatus($v.solicitud.bd)"
          class="form-control"
        >
          <option value="1">Base de datos 1</option>
          <option value="2">Base de datos 2</option>
          <option value="3">Base de datos 3</option>
        </select>
        <div class="errores" v-if="!$v.solicitud.bd.required">Requerido</div>
      </div>
      <div class="form-group col-md-4">
        <label>Solicitud o documentos</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.soldocs"
          class="form-control"
          :class="estatus($v.solicitud.soldocs)"
        />
        <!--<div class="errores" v-if="!$v.solicitud.soldocs.required">
          Requerido
        </div>-->
      </div>
      <div class="form-group col-md-4">
        <label>Forma de captación</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.forma_captacion"
          class="form-control"
          :class="estatus($v.solicitud.forma_captacion)"
        />
        <!--<div class="errores" v-if="!$v.solicitud.forma_captacion.required">
          Requerido
        </div>-->
      </div>


    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Grado de estudios:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.id_gradoestudio"
          :class="estatus($v.solicitud.id_gradoestudio)"
        >
          <option value="" selected>Elegir...</option>
          <option
            v-for="gradoestudio in gradoestudios"
            :key="gradoestudio.id_gradoestudio"
            v-bind:value="gradoestudio.id_gradoestudio"
          >
            {{ gradoestudio.gradoestudio }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_gradoestudio.required">
          Requerido
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Nombre(s)</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.nombre"
          class="form-control"
          :class="estatus($v.solicitud.nombre)"
        />
        <div class="errores" v-if="!$v.solicitud.nombre.required">
          Requerido
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Apellido paterno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.paterno"
          class="form-control"
          :class="estatus($v.solicitud.paterno)"
        />
        <div class="errores" v-if="!$v.solicitud.paterno.required">
          Requerido
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Apellido materno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.materno"
          class="form-control"
          :class="estatus($v.solicitud.materno)"
        />
        <div class="errores" v-if="!$v.solicitud.materno.required">
          Requerido
        </div>
      </div>

      <div class="form-group col-md-4">
        <label>Cargo</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.cargo"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Fecha de nacimiento (aaaa-mm-dd)</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.fechan"
          class="form-control"
        />
        
      </div>

    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-6">
        <label for="calle">Calle</label>
        <input
          maxlength="50"
          v-model.trim="solicitud.calle"
          :class="estatus($v.solicitud.calle)"
          class="form-control"
        />
        <div class="errores" v-if="!$v.solicitud.calle.required">Requerido</div>
      </div>
      <div class="form-group col-md-3">
        <label for="next">Número exterior</label>
        <input
          maxlength="50"
          v-model.trim="solicitud.next"
          :class="estatus($v.solicitud.next)"
          class="form-control"
        />
        <div class="errores" v-if="!$v.solicitud.next.required">Requerido</div>
      </div>
      <div class="form-group col-md-3">
        <label for="nint">Número interior</label>
        <input
          maxlength="50"
          v-model.trim="solicitud.nint"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-3">
        <label for="cp">CP</label>
        <input
          maxlength="5"
          v-model="solicitud.cp"
          class="form-control"
          :class="estatus($v.solicitud.cp)"
          v-on:change="buscaCp"
        />
        <div class="errores" v-if="!$v.solicitud.cp.required">Requerido</div>
      </div>
      <div class="form-group col-md-4">
        <label for="id_estado">Estado</label>
        <select
          v-model="solicitud.id_estado"
          v-on:change="actualizaMunicipios"
          :class="estatus($v.solicitud.id_estado)"
          class="form-control"
        >
          <option
            v-for="estado in estados"
            :key="estado.id_estado"
            v-bind:value="estado.id_estado"
          >
            {{ estado.estado }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_estado.required">Requerido</div>
      </div>
      <div class="form-group col-md-5">
        <label for="id_municipio">Municipio</label>
        <select
          v-model="solicitud.id_municipio"
          v-on:change="actualizaLocalidades"
          :class="estatus($v.solicitud.id_municipio)"
          class="form-control"
        >
          <option
            v-for="municipio in municipios"
            :key="municipio.id_municipio"
            v-bind:value="municipio.id_municipio"
          >
            {{ municipio.municipio }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_municipio.required">Requerido</div>
      </div>
      <div class="form-group col-md-5">
        <label for="id_localidad">Localidad</label>
        <select
          v-model="solicitud.id_localidad"
          v-on:change="actualizaCp"
          :class="estatus($v.solicitud.id_localidad)"
          class="form-control"
        >
          <option
            v-for="localidad in localidades"
            :key="localidad.id_localidad"
            v-bind:value="localidad.id_localidad"
          >
            {{ localidad.tipo }} - {{ localidad.localidad }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_localidad.required">Requerido</div>
      </div>
      <div class="form-group col-md-4">
        <label>Teléfono</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.telefono"
          class="form-control"
          :class="estatus($v.solicitud.telefono)"
        />
        <div class="errores" v-if="!$v.solicitud.telefono.required">
          Requerido
        </div>
        <div class="errores" v-if="!$v.solicitud.telefono.telValido">
          Incorrecto
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Correo electrónico</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.email"
          class="form-control"
          :class="estatus($v.solicitud.email)"
        />
        <div class="errores" v-if="!$v.solicitud.email.required">Requerido</div>
        <div class="errores" v-if="!$v.solicitud.email.email">Incorrecto</div>
      </div>    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Tipo solicitud:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.id_tiposolicitud"
          :class="estatus($v.solicitud.id_tiposolicitud)"
        >
          <option value="" selected>Elegir...</option>
          <option
            v-for="tipo in tiposolicitud"
            :key="tipo.id_tiposolicitud"
            v-bind:value="tipo.id_tiposolicitud"
          >
            {{ tipo.tiposolicitud }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_tiposolicitud.required">
          Requerido
        </div>
      </div>
      
      <div class="form-group col-md-4">
        <label>Prioridad:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.id_prioridad"
          :class="estatus($v.solicitud.id_prioridad)"
        >
          <option value="" selected>Elegir...</option>
          <option
            v-for="prioridad in prioridades"
            :key="prioridad.id_prioridad"
            v-bind:value="prioridad.id_prioridad"
          >
            {{ prioridad.prioridad }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_prioridad.required">
          Requerido
        </div>
      </div>

      <div class="form-group col-md-4">
        <label>Dependencia:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.id_area"
          :class="estatus($v.solicitud.id_area)"
        >
          <option value="" selected>Elegir...</option>
          <option
            v-for="area in areas"
            :key="area.id_area"
            v-bind:value="area.id_area"
          >
            {{ area.area }}
          </option>
        </select>
        <div class="errores" v-if="!$v.solicitud.id_area.required">
          Requerido
        </div>
      </div>
    </div>
    <div class="form-row grupo" v-if="false">
      <b-form-group label="Instrucciones:">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="solicitud.instrucciones"
        :options="instrucciones"
        class="mb-3"
        value-field="id_instruccion"
        text-field="instruccion"
        name="flavour-1"
      ></b-form-checkbox-group>
    </b-form-group>
    </div>

    <div class="form-row grupo">
      <div class="form-group col-md-6">
        <label>Asunto de la solicitud</label>
        <textarea
          style="max-width: 500px; height:175px;"
          v-model.trim="solicitud.asunto"
          class="form-control"
          :class="estatus($v.solicitud.asunto)"
        />
        <div class="errores" v-if="!$v.solicitud.asunto.required">Requerido</div>
      </div>
      <div class="form-group col-md-6">
        <label>Indicaciones de la solicitud</label>
        <textarea
          style="max-width: 500px; height:175px;"
          v-model.trim="solicitud.indicacion"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-6">
        <label>Descripción de la solicitud</label>
        <textarea
          style="max-width: 500px; height:175px;"
          v-model.trim="solicitud.descripcion"
          class="form-control"
          :class="estatus($v.solicitud.descripcion)"
        />
        <div class="errores" v-if="!$v.solicitud.descripcion.required">Requerido</div>
      </div>
      <div class="form-group col-md-6">
        <label>Archivos</label> &nbsp;
        <a href="javascript:;" @click="agregarImagen()">
          <font-awesome-icon size="lg" icon="image" />
        </a> &nbsp;
        <a href="javascript:;" @click="agregarPdf()">
          <font-awesome-icon size="lg" icon="file-pdf" />
        </a>
        <div style="border: 1px solid #ced4da; height: 175px; overflow-y: auto; border-radius: 0.25rem;">
          <ol>
            <li v-for="(archivo, pos) in archivos" v-bind:key="archivo.id">
              <a href="javascript:;" @click="abrirArchivo(archivo)">
                <font-awesome-icon :icon="archivo.tipo=='pdf'?'file-pdf':'image'" />
                {{archivo.nombre}}</a>&nbsp; 
              <a href="javascript:;" @click="eliminarArchivo(pos)">
                <font-awesome-icon icon="trash" />
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button
        class="btn btn-primary"
        v-bind:disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Enviar solicitud
      </button>
    </div>
    <b-modal ref="verimagend" :title="archivo.nombre">
      <div class="form-group col-md-12">
        <img :src="archivo.archivo" class="img-fluid"/>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="imagend" title="Agregar imagen">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">
          Requerido
        </div>
      </div>
      <div class="col">
        <label for="descripcion">Foto: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-imagen
            v-model="archivo.archivo"
          ></archivo-imagen>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">
          Requerido
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarImagen()">Guardar</b-button>
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>

    </b-modal>
    <b-modal ref="pdfd" title="Agregar PDF">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">
          Requerido
        </div>
      </div>
      <div class="col">
        <label for="descripcion">Archivo: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-pdf
            v-model="archivo.archivo"
          ></archivo-pdf>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">
          Requerido
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarPdf()">Guardar</b-button>
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>

    </b-modal>
    <b-modal v-model="guardando" title="Guardando" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {PDF_URL} from '@/api'
import ArchivoImagen from "@/components/ArchivoImagen";
import ArchivoPdf from "@/components/ArchivoPdf";
import { required, email } from "vuelidate/lib/validators";
import BarraProgreso  from "@/components/BarraProgreso";
import moment from 'moment';

export default {
  name: "Solicitud",
  components: { ArchivoImagen, ArchivoPdf, BarraProgreso  },
  data() {
    return {
      tiposolicitud: [],
      estados: [],
      municipios: [],
      localidades: [],
      prioridades: [],
      gradoestudios: [],
      archivos: [],
      archivo: {nombre:null,archivo: null, tipo: null},
      solicitud: { 
        //curp: null, 
        fecha: fechaActual(),
        telefono: null,
        id_tiposolicitud: null,
        id_prioridad: null,
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        calle: null,
        next: null,
        nint: null, 
        cp: null,
        id_estado: 29,
        id_municipio: null,
        id_localidad: null,
        descripcion: null,
        foto: null, 
        },
      errores: {},
      encontrado: false,
      guardando: false,
      avanceGuardando: 20,
      escolaridades: [],
      areas: [],
      instrucciones: [],
    };
  },
  validations() {
    return {
      solicitud: {
        telefono: { required:false, telValido },
        //curp: { required, curpValido },
        id_tiposolicitud: { required },
        id_prioridad: { required },
        nombre: { required },
        paterno: { required },
        materno: { required },
        email: {  required:false, email },
        calle: {required:false  },
        next: { required:false },
        fecha: { required, fechaValida },
        cp: { required:false },
        id_estado: { required:false },
        id_gradoestudio: { required:false },
        id_municipio: { required:false },
        id_localidad: { required:false },
        descripcion: { required:false },
        asunto: { required:false },
        id_area: {},
        bd: {},
        fechan: { required:false },
        soldocs: {},
        forma_captacion: {},
        foto: {},
      },
      archivo: {
        nombre: {required},
        archivo: {required},
      }
    };
  },
  methods: {
    guardar: function () {
      this.$v.solicitud.$touch();
      if (!this.$v.solicitud.$invalid) {
        this.guardando = true;
        this.solicitud.archivos = this.archivos;

        api.guardarSolicitud(this.solicitud, this.avance).then((r) => {
            this.$parent.aviso(r.data.mensaje);
            this.guardando = false;
            window.open(`${PDF_URL}/solicitud.php?id=${r.data.id}`,'_blank');      
            this.$router.push({ path: '/' });
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
            this.guardando = false;
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    avance(progreso) {
      console.log(progreso);
      this.avanceGuardando = Math.round((progreso.loaded * 100) / progreso.total);
    },
    eliminarArchivo(pos) {
      this.archivos.splice(pos, 1);
    },
    abrirArchivo(archivo) {
      if (archivo.tipo == 'img') {
        this.abrirImagen(archivo);
      } else {
        this.abrirPdf(archivo);
      }
    },
    abrirImagen(archivo) {
      this.archivo = archivo;
      this.$refs.verimagend.show();
    },
    abrirPdf(archivo) {
        const win = window.open("", "_blank");
        let html = "";

        html += '<html><head><title>'+archivo.nombre+'</title></head><body style="margin:0!important">';
        html +=
          '<embed width="100%" height="100%" src="' +
          archivo.archivo +
          '" type="application/pdf" />';
        html += "</body></html>";

        setTimeout(() => {
          win.document.write(html);
        }, 0);
    },
    agregarPdf() {
      this.archivo = {nombre:null,archivo: null, tipo: 'pdf'};
      this.$refs.pdfd.show();
    },
    agregarImagen() {
      this.archivo = {nombre:null,archivo: null, tipo: 'img'};
      this.$refs.imagend.show();
    },
    guardarPdf() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.pdfd.hide();
      } else {
        this.$parent.error("Información incompleta");        
      }
    },
    guardarImagen() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.imagend.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    buscaTelefono() {
      api.buscaTelefono(this.solicitud.telefono).then((datos) => {
        if (datos.data.respuesta) {
          let p = datos.data.datos;
          if (p!=null) {
            //this.solicitud.curp = p.curp;
            this.solicitud.nombre = p.nom;
            this.solicitud.paterno = p.paterno;
            this.solicitud.materno = p.materno;
            this.solicitud.calle = p.calle;
            this.solicitud.next = p.next;
            this.solicitud.nint = p.nint;
            this.solicitud.cp = p.cp;
            //this.solicitud.id_estado = p.id_estado;
            this.solicitud.id_municipio = p.id_municipio;
            this.solicitud.id_localidad = p.id_localidad;
            this.encontrado = true;            
            this.actualizaLocalidades();
            //this.actualizaFechaN();
          }
        }
      });
    },
    /*actualizaFechaN() {
      var m = this.solicitud.curp.match(/(\d{2})(\d{2})(\d{2})/);
      if (m != null) {
        var anio = parseInt(m[1],10)+1900;
        if (anio < 1950) {
          anio += 100;
        }
        var mes = m[2].padStart(2,'0');
        var dia = m[3].padStart(2,'0');
        this.solicitud.fechan = `${anio}-${mes}-${dia}`;
      } else {
        this.solicitud.fechan = '';
      }
    },
    buscaCurp() {
      this.actualizaFechaN();
      api.buscaCurp(this.solicitud.curp).then((datos) => {
        if (datos.data.respuesta) {
          let p = datos.data.datos;
          if (p != null) {
            this.solicitud.telefono = p.tel;
            this.solicitud.nombre = p.nom;
            this.solicitud.paterno = p.paterno;
            this.solicitud.materno = p.materno;
            this.solicitud.calle = p.calle;
            this.solicitud.next = p.next;
            this.solicitud.nint = p.nint;
            this.solicitud.cp = p.cp;
            //this.solicitud.id_estado = p.id_estado;
            this.solicitud.id_municipio = p.id_municipio;
            this.solicitud.id_localidad = p.id_localidad;
            this.encontrado = true;
            this.actualizaLocalidades();
            this.$v.$touch();
          }
        }
      });
    },*/
    buscaCp() {},
    actualizaCp() {
      let id = this.solicitud.id_localidad;
      this.localidades.forEach((localidad) => {
        if (localidad.id_localidad == id) {
          this.solicitud.cp = localidad.cp;
        }
      });
    },
    actualizaMunicipios() {
      api
        .listarMunicipios(this.solicitud.id_estado)
        .then((respuesta) => (this.municipios = respuesta.data));
    },
    actualizaLocalidades() {
      api
        .listarLocalidades(this.solicitud.id_municipio)
        .then((respuesta) => (this.localidades = respuesta.data));
    },

    estatus(validation) {
      console.log(validation);
      if (validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    api
      .listarTipoSolicitud()
      .then((respuesta) => (this.tiposolicitud = respuesta.data));
    api
      .listarPrioridades()
      .then((respuesta) => (this.prioridades = respuesta.data));
    api
      .listarAreas()
      .then((respuesta) => (this.areas = respuesta.data));
    api
      .listarGradoEstudio()
      .then((respuesta) => (this.gradoestudios = respuesta.data));
    api
      .listarInstrucciones()
      .then((respuesta) => (this.instrucciones = respuesta.data));
    api.listarEstados().then((respuesta) => (this.estados = respuesta.data));
    this.actualizaMunicipios();

    let id = this.$route.params.id;
    if (id > 0) {
      api.recuperarSolicitud(id).then((respuesta) => {
        this.solicitud = respuesta.data.solicitud;
      });
    }
  },
};
function fechaActual() {
  let date = Date.now()
  return moment(date).format('YYYY-MM-DD');
}
/*
function curpValido(curp) {
    if (typeof curp == "undefined") {
        return true;
    }
    if (curp == null) {
        return true;
    }
    if (curp.length != 18) {
        return false;
    }

    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    var validado = curp.match(re);

    if (!validado) { //Coincide con el formato general?
        return false;
    }

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10)
            return 0;
        return lngDigito;
    }
    if (validado[2] != digitoVerificador(validado[1]))
        return false;

    return true; //Validado
}*/
function telValido(tel) {
    if (typeof tel == "undefined") {
        return true;
    }
    if (tel == null) return true;
    if (tel.length != 10) return false;
    var re = RegExp(/^(\d{10})$/g);
    var validado = tel.match(re) != null;
    return validado;
}
function fechaValida(fecha) {
    if (typeof fecha == "undefined") {
        return true;
    }
    if (fecha == null) return true;
    if (fecha.length != 10) return false;
    var re = RegExp(/^(\d\d\d\d-\d\d-\d\d)$/g);
    var validado = fecha.match(re) != null;
    return validado;
}
</script>

