<template>
  <div>
    <div class="table-responsive">
      <table>
        <tr>
          <td>Teléfono:</td>
          <td style="font-weight: bold">{{ solicitud.telefono }}</td>
        </tr>
        <tr>
          <td>Nombre:</td>
          <td style="font-weight: bold">
            {{ solicitud.paterno }} {{ solicitud.materno }}
            {{ solicitud.nombre }}
          </td>
        </tr>
        <tr>
          <td>Tipo de solicitud:</td>
          <td style="font-weight: bold">{{ solicitud.tiposolicitud }}</td>
        </tr>
        <tr>
          <td>Prioridad:</td>
          <td style="font-weight: bold">{{ solicitud.prioridad }}</td>
        </tr>
        <tr>
          <td>Dirección:</td>
          <td style="font-weight: bold">
            {{ solicitud.calle }} {{ solicitud.next }} {{ solicitud.nint }},
            {{ solicitud.localidad }}, {{ solicitud.municipio }},
            {{ solicitud.estado }} {{ solicitud.cp }}
          </td>
        </tr>
        <tr>
          <td>Descripcion:</td>
          <td style="font-weight: bold">{{ solicitud.descripcion }}</td>
        </tr>
      </table>
    </div>
    <br />
    <h4>Avance</h4>
    <div class="form-row grupo">
      <div class="col-md-6">
        <div class="form-group">
          <label>Estatus solicitud:</label>
          <select
            class="form-control"
            v-model="avance.id_estatus"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="e in estatuses"
              :key="e.id_estatus"
              v-bind:value="e.id_estatus"
            >
              {{ e.estatus }}
            </option>
          </select>
          <div class="errores" v-if="!$v.avance.id_estatus.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Dependencia (cambiar para turnar a otra área):</label>
          <select
            class="form-control"
            v-model="avance.id_area"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="area in areas"
              :key="area.id_area"
              v-bind:value="area.id_area"
            >
              {{ area.area }}
            </option>
          </select>
          <div class="errores" v-if="!$v.avance.id_area.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Comentarios</label>
          <textarea
            style="height: 100px"
            v-model.trim="avance.comentarios"
            class="form-control"
            :class="estatus($v.avance.comentarios)"
          />
          <div class="errores" v-if="!$v.avance.comentarios.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Archivos</label> &nbsp;
        <a href="javascript:;" @click="agregarImagen()">
          <font-awesome-icon size="lg" icon="image" />
        </a>
        &nbsp;
        <a href="javascript:;" @click="agregarPdf()">
          <font-awesome-icon size="lg" icon="file-pdf" />
        </a>
        <div
          style="
            border: 1px solid #ced4da;
            height: 100px;
            width: 100%;
            overflow-y: auto;
            border-radius: 0.25rem;
          "
        >
          <ol>
            <li v-for="(archivo, pos) in archivos" v-bind:key="archivo.id">
              <a href="javascript:;" @click="abrirArchivo(archivo)">
                <font-awesome-icon
                  :icon="archivo.tipo == 'pdf' ? 'file-pdf' : 'image'"
                />
                {{ archivo.nombre }}</a
              >&nbsp;
              <a href="javascript:;" @click="eliminarArchivo(pos)">
                <font-awesome-icon icon="trash" />
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <b-modal ref="verimagend" :title="archivo.nombre">
      <div class="form-group col-md-12">
        <img :src="archivo.archivo" class="img-fluid" />
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="imagend" title="Agregar imagen">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">Requerido</div>
      </div>
      <div class="col">
        <label for="descripcion">Foto: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-imagen v-model="archivo.archivo"></archivo-imagen>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">Requerido</div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarImagen()"
          >Guardar</b-button
        >
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal ref="pdfd" title="Agregar PDF">
      <div class="form-group col-md-12">
        <label>Nombre</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="archivo.nombre"
          class="form-control"
          :class="estatus($v.archivo.nombre)"
        />
        <div class="errores" v-if="!$v.archivo.nombre.required">Requerido</div>
      </div>
      <div class="col">
        <label for="descripcion">Archivo: </label>
        <div style="max-width: 400px; max-height: 400px; overflow-y: auto">
          <archivo-pdf v-model="archivo.archivo"></archivo-pdf>
        </div>
        <div class="errores" v-if="!$v.archivo.archivo.required">Requerido</div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" variant="primary" @click="guardarPdf()"
          >Guardar</b-button
        >
        <b-button class="float-right" @click="hide()">Cerrar</b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";
import ArchivoImagen from "@/components/ArchivoImagen";
import ArchivoPdf from "@/components/ArchivoPdf";
import BarraProgreso from "@/components/BarraProgreso";

export default {
  name: "SolicitudAvanceMas",
  components: { ArchivoImagen, ArchivoPdf, BarraProgreso },
  props: {
    solicitud: Object,
    avanceEditar: Object,
  },
  data() {
    return {
      areas: [],
      estatuses: [],
      avance: { id_area: null, id_estatus: null, comentarios: null },
      archivos: [],
      eliminados: [],
      archivo: { nombre: null, archivo: null, tipo: null },
      guardando: false,
      avanceGuardando: 20,
    };
  },
  methods: {
    guardar: function (papa) {
      return new Promise((resolve) => {
        this.$v.avance.$touch();
        if (!this.$v.avance.$invalid) {
          this.guardando = true;
          this.avance.archivos = this.archivos;
          this.avance.eliminados = this.eliminados;
          this.avance.id_solicitud = this.solicitud.id_solicitud;
          api
            .guardarAvance(this.avance, this.avances)
            .then((r) => {
              papa.aviso(r.data.mensaje);
              this.guardando = false;
              resolve(true);
            })
            .catch((error) => {
              papa.error("Error al guardar la información " + error);
              this.guardando = false;
            });
        } else {
          papa.error("Información incompleta");
        }
      });
    },
    avances(progreso) {
      console.log(progreso);
      this.avanceGuardando = Math.round(
        (progreso.loaded * 100) / progreso.total
      );
    },
    eliminarArchivo(pos) {
      var archivo = this.archivos[pos];
      this.archivos.splice(pos, 1);
      if (archivo.id_archivo) {
        this.eliminados.push(archivo.id_archivo);
      }
    },
    abrirArchivo(archivo) {
      if (archivo.tipo == "img") {
        this.abrirImagen(archivo);
      } else {
        this.abrirPdf(archivo);
      }
    },
    abrirImagen(archivo) {
      this.archivo = archivo;
      this.$refs.verimagend.show();
    },
    abrirPdf(archivo) {
      const win = window.open("", "_blank");
      let html = "";

      html +=
        "<html><head><title>" +
        archivo.nombre +
        '</title></head><body style="margin:0!important">';
      html +=
        '<embed width="100%" height="100%" src="' +
        archivo.archivo +
        '" type="application/pdf" />';
      html += "</body></html>";

      setTimeout(() => {
        win.document.write(html);
      }, 0);
    },
    agregarPdf() {
      this.archivo = { nombre: null, archivo: null, tipo: "pdf" };
      this.$refs.pdfd.show();
    },
    agregarImagen() {
      this.archivo = { nombre: null, archivo: null, tipo: "img" };
      this.$refs.imagend.show();
    },
    guardarPdf() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.pdfd.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    guardarImagen() {
      this.$v.archivo.$touch();
      if (!this.$v.archivo.$invalid) {
        this.archivos.push(this.archivo);
        this.$refs.imagend.hide();
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  validations: {
    avance: {
      id_estatus: { required },
      id_area: { required },
      comentarios: { required },
    },
    archivo: {
        nombre: { required },
        archivo: { required },
      },
  },

  mounted() {
    this.avance.id_area = this.solicitud.id_area;
    if (this.avanceEditar != null) {
      this.avance = Object.assign({}, this.avanceEditar);
      if (this.avanceEditar.archivos) {
        this.archivos  = Array.from(this.avanceEditar.archivos);
      }
    }
    api
      .listarEstatusSolicitud()
      .then((respuesta) => (this.estatuses = respuesta.data));
    api.listarAreas().then((respuesta) => (this.areas = respuesta.data));
  },
};
</script>

