<template>
  <div>
    <h4>Gráficas de atencíon por dependencia</h4>
    
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Elija la dependencia</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_area"
        >
          <option value="" selected>Todas</option>
          <option
            v-for="area in areas"
            :key="area.id_area"
            v-bind:value="area.id_area"
          >
            {{ area.area }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Fecha inicial</label>
        <b-form-datepicker
          v-model="busqueda.fechai"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-4">
        <label>Fecha final</label>
        <b-form-datepicker
          v-model="busqueda.fechaf"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="buscar">
          Buscar
        </button>
      </div>
    </div>

    <div>
      <bar-chart :chart-data="datos" :width="8" :height="3" />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import BarChart from "../components/BarChart";

export default {
  name: "GAtencionArea",
  components: { BarChart },
  data() {
    return {
      areas: [],
      datos: {},
      busqueda: {
        id_area: '',
        fechai: null,
        fechaf: null,
      },
    };
  },
  methods: {
    buscar() {
      api
        .graficaAreas(this.busqueda)
        .then((respuesta) => (this.datos = respuesta.data));
    },
  },
  mounted() {
    api.listarAreas().then((respuesta) => (this.areas = respuesta.data));
    this.buscar();
  },
};
</script>

