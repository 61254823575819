<template>
  <div>
    <div class="table-responsive">
      <table>
        <tr>
          <td>Teléfono:</td>
          <td style="font-weight: bold">{{ solicitud.telefono }}</td>
        </tr>
        <tr>
          <td>Nombre:</td>
          <td style="font-weight: bold">
            {{ solicitud.paterno }} {{ solicitud.materno }}
            {{ solicitud.nombre }}
          </td>
        </tr>
        <tr>
          <td>Tipo de solicitud:</td>
          <td style="font-weight: bold">{{ solicitud.tiposolicitud }}</td>
        </tr>
        <tr>
          <td>Prioridad:</td>
          <td style="font-weight: bold">{{ solicitud.prioridad }}</td>
        </tr>
        <tr>
          <td>Dirección:</td>
          <td style="font-weight: bold">
            {{ solicitud.calle }} {{ solicitud.next }} {{ solicitud.nint }},
            {{ solicitud.localidad }}, {{ solicitud.municipio }},
            {{ solicitud.estado }} {{ solicitud.cp }}
          </td>
        </tr>
        <tr>
          <td>Descripcion:</td>
          <td style="font-weight: bold">{{ solicitud.descripcion }}</td>
        </tr>
        <tr>
          <td>Comentarios:</td>
          <td style="font-weight: bold">{{ solicitud.observaciones }}</td>
        </tr>
      </table>
    </div>
    <br />
    <h4>Historial</h4>
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Fecha</th>
          <th>Dependencia</th>
          <th>Comentarios</th>
          <th>Usuario</th>
          <th>Archivos</th>
          <th>Estatus</th>
          <th></th>
        </thead>
        <tr v-for="(avance, pos) in avances" :key="avance.id_avance">
          <td>{{ avance.fecha }}</td>
          <td>{{ avance.area}}</td>
          <td>{{ avance.comentarios }}</td>
          <td>{{ avance.usuario }}</td>
          <td>
            <ul style="padding-left: 0px;">
              <li v-for="archivo in avance.archivos" :key="archivo.id_archivo">
                <a href="javascript:;" @click="abrirArchivo(archivo)">
                <font-awesome-icon :icon="archivo.tipo=='pdf'?'file-pdf':'image'" />
                {{archivo.nombre}}</a>&nbsp;
              </li>
            </ul> 
          </td>
          <td>{{ avance.estatus }}</td>
          <td>
            <a 
              v-if="pos == avances.length-1 && avance.id_usuario == $store.getters.usuario.id_usuario" 
              href="javascript:;" @click="editarAvance(solicitud, avance)">
              <font-awesome-icon size="lg" icon="edit" /></a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {ARCHIVOS_URL} from '@/api'
import api from "@/api";

export default {
  name: "SolicitudAvance",
  props: {
    solicitud: { type: Object, required: true },
  },
  data() {
    return {
      avances: [],
    };
  },
  methods: {
    editarAvance(solicitud, avance) {
      this.$emit('editar', solicitud, avance);
    },
    abrirArchivo(archivo) {
        window.open(`${ARCHIVOS_URL}/${archivo.ruta}`,'_blank');
    },
    actualiza() {
      api.listarAvances(this.solicitud.id_solicitud).then((respuesta) => {
            this.avances = respuesta.data;
          });
    }
  },  
  watch: {
    solicitud: {
      immediate: true,
      // We have to move our method to a handler field
      handler() {
        if (this.solicitud.id_solicitud > 0) {
          api.listarAvances(this.solicitud.id_solicitud).then((respuesta) => {
            this.avances = respuesta.data;
          });
        }
      },
    },
  },
};
</script>

