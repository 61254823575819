<template>
  <div>
    <h4>Bitácora</h4>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Dependencia</th>
          <th>Responsable</th>
          <th>Correo electrónico</th>
          <th>Celular</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id_solicitud">
          <td>{{ elemento.area }}</td>
          <td>{{ elemento.responsable }}</td>
          <td>{{ elemento.email }}</td>
          <td>{{ elemento.telefono }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la dependencia</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Dependencia</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.area"
            class="form-control"
            :class="estatus($v.actual.area)"
          />
          <div class="errores" v-if="!$v.actual.area.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Usuario</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.id_usuario_responsable"
            :class="estatus($v.actual.id_usuario_responsable)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="usuario in usuarios"
              :key="usuario.id_usuario"
              v-bind:value="usuario.id_usuario"
            >
              {{ usuario.nombre }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label>Responsable</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.responsable"
            class="form-control"
            :class="estatus($v.actual.responsable)"
          />
          <div class="errores" v-if="!$v.actual.responsable.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Cargo</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.cargo"
            class="form-control"
            :class="estatus($v.actual.cargo)"
          />
          <div class="errores" v-if="!$v.actual.cargo.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Correo electrónico</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.email"
            class="form-control"
            :class="estatus($v.actual.email)"
          />
          <div class="errores" v-if="!$v.actual.email.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.email.email">Incorrecto</div>
        </div>
        <div class="form-group col-md-12">
          <label>Teléfono</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.telefono"
            class="form-control"
            :class="estatus($v.actual.telefono)"
          />
          <div class="errores" v-if="!$v.actual.telefono.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.telefono.telValido">Incorrecto</div>
        </div>
        <div class="form-group col-md-6">
          <label>Teléfono dependencia</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.telefonod"
            class="form-control"
            :class="estatus($v.actual.telefonod)"
          />
          <div class="errores" v-if="!$v.actual.telefonod.telValido">Incorrecto</div>
        </div>
        <div class="form-group col-md-6">
          <label>Ext. dependencia</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.ext"
            class="form-control"
            :class="estatus($v.actual.ext)"
          />
        </div>

      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar dependencia",
      listado: [],
      usuarios: [],
      buscando: false,
      actual: {},
    };
  },
  validations() {
    return {
      actual: {
        area: {
          required,
        },
        responsable: {
          required,
        },
        cargo: {
          required,
        },
        email: {
          required, email,
        },
        telefono: {
          required, telValido
        },
        telefonod: {
          telValido
        },
        ext: {
        },
        id_usuario_responsable: {
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar dependencia";
      this.actual = {};
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo = "Editar dependencia",
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar la dependencia: "+elemento.area)) {
        api.eliminarArea(elemento.id_area)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaAreas();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarArea(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaAreas();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaAreas() {
      api.listarAreas().then((r) => {
        this.listado = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaAreas();
    api.listarUsuarios().then((r) => {
      this.usuarios = r.data;
    });
  },
};
function telValido(tel) {
  console.log(tel);
  if (typeof tel == "undefined") {
    return true;
  }
  if (tel == null) return true;
  if (tel.length != 10) return false;
  var re = RegExp(/^(\d{10})$/g);
  var validado = tel.match(re) != null;
  return validado;
}
</script>

