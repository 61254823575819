<template>
  <div>

    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Teléfono</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.telefono"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>CURP</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.curp"
          readonly
          class="form-control"
        />
      </div>
    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Tipo solicitud:</label>
        <input
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.tiposolicitud"
          readonly
        />
      </div>
      <div class="form-group col-md-4">
        <label>Prioridad:</label>
        <input
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.prioridad"
          readonly
        />
      </div>
      <div class="form-group col-md-4">
        <label>Dependencia:</label>
        <input
          class="form-control"
          style="max-width: 500px"
          v-model="solicitud.area"
          readonly
        />
      </div>
    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Nombre(s)</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.nombre"
          class="form-control"
                  readonly
        />
      </div>
      <div class="form-group col-md-4">
        <label>Apellido paterno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.paterno"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Apellido materno</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.materno"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Fecha de nacimiento</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.fechan"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Correo electrónico</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="solicitud.email"
          readonly
          class="form-control"
        />
      </div>
    </div>
    <div class="form-row grupo">
      <div class="form-group col-md-6">
        <label>Calle</label>
        <input
          maxlength="50"
          readonly
          v-model.trim="solicitud.calle"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-3">
        <label>Número exterior</label>
        <input
          maxlength="50"
          v-model.trim="solicitud.next"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-3">
        <label>Número interior</label>
        <input
          maxlength="50"
          readonly
          v-model.trim="solicitud.nint"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-3">
        <label>CP</label>
        <input
          maxlength="5"
          v-model="solicitud.cp"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Estado</label>
        <input
          v-model="solicitud.estado"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-5">
        <label>Municipio</label>
        <input
          v-model="solicitud.municipio"
          readonly
          class="form-control"
        />
      </div>
      <div class="form-group col-md-5">
        <label>Localidad</label>
        <input
          v-model="solicitud.localidad"
          readonly
          class="form-control"
        />
      </div>
    </div>

    <div class="form-row grupo">
      <div class="form-group col-md-6">
        <label>Descripción de la solicitud</label>
        <textarea readonly
          style="max-width: 500px; height:175px;"
          v-model.trim="solicitud.descripcion"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="descripcion">Archivos: </label>
        <div style="border: 1px solid #ced4da; height: 175px; overflow-y: auto; border-radius: 0.25rem;">
          <ol>
            <li v-for="(archivo) in solicitud.archivos" v-bind:key="archivo.id">
              <a href="javascript:;" @click="abrirArchivo(archivo)">
                <font-awesome-icon :icon="archivo.tipo=='pdf'?'file-pdf':'image'" />
                {{archivo.nombre}}</a>&nbsp; 
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ARCHIVOS_URL} from '@/api'
export default {
  name: "SolicitudVer",
  props: {
    solicitud: Object,
  },
  data() {
    return {
    }
  },
  methods: {
    abrirArchivo(archivo) {
        window.open(`${ARCHIVOS_URL}/${archivo.ruta}`,'_blank');
    }
  },
};
</script>

