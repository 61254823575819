<template>
  <div>
    <solicitud-avance :solicitud="solicitud" />
  </div>
</template>

<script>
import api from "@/api";
import SolicitudAvance from "./SolicitudAvance.vue";

export default {
  name: "SolicitudD",
  components: { SolicitudAvance },
  data() {
    return {
      archivos: [],
      avances: [],
      solicitud: {
        id_solicitud: 1,
        curp: '',
        telefono: '',
        id_tiposolicitud: '',
        id_prioridad: '',
        nombre: '',
        paterno: '',
        materno: '',
        email: '',
        calle: '',
        next: '',
        nint: '',
        cp: '',
        id_estado: 29,
        id_municipio: '',
        id_localidad: '',
        descripcion: '',
        foto: '',
      },
    };
  },
  methods: {
    abrirArchivo(archivo) {
      if (archivo.tipo == "img") {
        this.abrirImagen(archivo);
      } else {
        this.abrirPdf(archivo);
      }
    },
    abrirImagen(archivo) {
      this.archivo = archivo;
      this.$refs.verimagend.show();
    },
    abrirPdf(archivo) {
      const win = window.open("", "_blank");
      let html = "";

      html +=
        "<html><head><title>" +
        archivo.nombre +
        '</title></head><body style="margin:0!important">';
      html +=
        '<embed width="100%" height="100%" src="' +
        archivo.archivo +
        '" type="application/pdf" />';
      html += "</body></html>";

      setTimeout(() => {
        win.document.write(html);
      }, 0);
    },
  },
  mounted() {
    var id = this.$route.params.id;
    if (id > 0) {
      api.abrirSolicitud(id).then((respuesta) => {
        this.solicitud = respuesta.data;
      });
    }
  },
};
</script>
