<template>
  <div>
    <h4>Listado de usuarios</h4>
    <div class="text-right">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Usuario</th>
          <th>Nombre</th>
          <th>Estatus</th>
          <th></th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id_usuario">
          <td>{{ elemento.usuario }}</td>
          <td>{{ elemento.nombre }}</td>
          <td>{{ elemento.estatus }}</td>
          <td>
            <a href="javascript:;" @click="editar(elemento)">
              <font-awesome-icon size="lg" icon="edit" />
            </a>
            <a href="javascript:;" @click="eliminar(elemento)">
              <font-awesome-icon size="lg" icon="trash" />
            </a>
          </td>
        </tr>
      </table>
    </div>
    <b-modal ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del usuario</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-12">
          <label>Nombre completo</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nombre"
            class="form-control"
            :class="estatus($v.actual.nombre)"
          />
          <div class="errores" v-if="!$v.actual.nombre.required">Requerido</div>
        </div>
        <div class="form-group col-md-4">
          <label>Tipo usuario:</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.id_tipousuario"
            :class="estatus($v.actual.id_tipousuario)"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="tu in tiposusuario"
              :key="tu.id_tipousuario"
              v-bind:value="tu.id_tipousuario"
            >
              {{ tu.tipousuario }}
            </option>
          </select>
          <div class="errores" v-if="!$v.actual.id_tipousuario.required">Requerido</div>
        </div>        
        <div class="form-group col-md-12">
          <label>Usuario</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.usuario"
            class="form-control"
            :class="estatus($v.actual.usuario)"
          />
          <div class="errores" v-if="!$v.actual.usuario.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label>Contraseña</label>
          <input type="password"
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.password"
            class="form-control"
            :class="estatus($v.actual.password)"
          />
          <div class="errores" v-if="!$v.actual.password.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.password.minLength">Longitud mínima de seis caracteres</div>
          <div class="errores" v-if="!$v.actual.password.iguales">Las contraseñas no coinciden</div>
        </div>
        <div class="form-group col-md-12">
          <label>Confirmar contraseña</label>
          <input type="password"
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.password2"
            class="form-control"
            :class="estatus($v.actual.password2)"
          />
          <div class="errores" v-if="!$v.actual.password2.required">Requerido</div>
          <div class="errores" v-if="!$v.actual.password2.iguales">Las contraseñas no coinciden</div>
        </div>
        <div class="form-group col-md-4">
          <label>Dependencia:</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.id_area"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="area in areas"
              :key="area.id_area"
              v-bind:value="area.id_area"
            >
              {{ area.area }}
            </option>
          </select>
        </div>        
        <div class="form-group col-md-12">
          <label>Estatus</label>
          <select
            class="form-control"
            style="max-width: 500px"
            v-model="actual.estatus"
            :class="estatus($v.actual.estatus)"
          >
            <option value="" selected>Elegir...</option>
            <option value="Activo">Activo</option>
            <option value="Inactivo">Inactivo</option>
          </select>
          <div class="errores" v-if="!$v.actual.estatus.required">Requerido</div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required, requiredIf, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "Usuarios",
  data() {
    return {
      titulo: "Agregar usuario",
      listado: [],
      actual: {password: null, password2: null},
      areas: [],
      tiposusuario: [],
    };
  },
  validations() {
    return {
      actual: {
        nombre: {
          required,
        },
        usuario: {
          required,
        },
        password: { 
          required: requiredIf(function (s) { 
            return !("id_usuario" in s);
          }),
          minLength: minLength(6), 
          iguales: sameAs('password2'),
        },
        password2: { 
          required: requiredIf(function (s) { 
            return !("id_usuario" in s);
          }),
          minLength: minLength(6), 
          iguales: sameAs('password'),
        },
        id_tipousuario: {
          required,
        },
        estatus: {
          required,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo= "Agregar usuario",
      this.actual = {password: '', password2: '' };
      this.$refs.detalle.show();
    },
    editar (elemento) {
      this.titulo= "Editar usuario",
      this.actual = Object.assign({password: '', password2: ''}, elemento);
      this.$refs.detalle.show();
    },
    eliminar (elemento) {
      if (confirm("Confirma eliminar el usuario: "+elemento.nombre)) {
        api.eliminarUsuario(elemento.id_usuario)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaUsuarios();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {this.$parent.aviso("No se pudo eliminar "+error);});
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api
          .guardarUsuario(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaUsuarios();
            } else {
              this.$parent.error("Error al guardar la información "+r.mensaje);
            }
          })
          .catch((error) => {
              this.$parent.error("Error al guardar la información "+error);
          });
      } else {
        this.$parent.error("Información incompleta");
        this.listado = [];
        this.buscando = false;
      }
    },
    abrirDetalle() {
      this.$refs.detalle.show();
    },
    actualizaUsuarios() {
      api.listarUsuarios().then((r) => {
        this.listado = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaUsuarios();
    api
      .listarAreas()
      .then((respuesta) => (this.areas = respuesta.data));
    api
      .listarTiposUsuario()
      .then((respuesta) => (this.tiposusuario = respuesta.data));
  },
};
</script>

