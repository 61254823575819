import { Bar, mixins } from 'vue-chartjs'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: [ 'opciones'],
  mounted () {
    this.addPlugin(ChartJsPluginDataLabels);
    var opts = this.opciones;
    if (typeof opts !== 'object') {
      opts = {}
    } 
    opts['scales'] = {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }]
    },
    opts['plugins'] = {
        datalabels: {
          color: "black",
          textAlign: "end",
          font: {
            weight: "bold",
            size: 14,
          }
        }
    }
    opts['responsive'] = true;
    opts['maintainAspectRatio'] = false;
    opts['height'] = 300;
    this.renderChart(this.chartData, opts);
  }
}