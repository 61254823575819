<template>
  <div>
    <div style="position: relative">
      <img regex = "([^\\s]+(\\.(?i)(jpe?g|png|gif|bmp))$)"
     

        :src="contenido != null && /\.(jpe?g|png|gif)$/.test(contenido) ? `${API_URL}/archivos/${contenido}` : contenido"
        ref="imagen"
        width="100%"
        style="min-height: 170px"
        class="img-responsive"
      />
      <div style="position: absolute; top: 0; right: 0">
        <button class="btn btn-secondary" v-on:click="tomarFoto">
          <font-awesome-icon icon="camera" />
        </button>
        <div style="height: 5px"></div>
        <button class="btn btn-secondary" v-on:click="subirFoto">
          <font-awesome-icon icon="image" />
        </button>
        <div style="height: 5px"></div>
        <button class="btn btn-secondary" v-on:click="recortarFoto">
          <font-awesome-icon icon="crop-alt" />
        </button>
        <div style="height: 5px"></div>
        <button class="btn btn-secondary" v-on:click="quitarFoto">
          <font-awesome-icon icon="trash" />
        </button>
      </div>
    </div>
    <b-modal
      ref="recortarfoto"
      title="Recortar foto"
      size="md"
      centered
      
    >
      <p>Ajuste el tamaño de la foto</p>
      <vue-cropper ref="cortador" src="../assets/imagen.jpg"> </vue-cropper>
      <template #modal-footer="{ hide }">
        <button v-on:click="fotoRecortada" class="btn btn-primary">
          Recortar foto
        </button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="tomarfoto"
      title="Tomar foto"
      size="md"
      centered
      hide-backdrop
    >
      <p>Enfoque la imagen que desea tomar</p>
      <foto ref="foto" v-on:foto-tomada="fotoTomada" width="400" height="340" />
      <template #modal-footer="{ hide }">
        <button v-on:click="fotografiar()" class="btn btn-primary">
          Tomar foto
        </button>
        <b-button v-if="false" class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Foto from "@/components/Foto";
import {API_URL} from '@/api'
export default {
  components: { VueCropper, Foto },
  name: "ArchivoImagen",
  props: {
    value: {},
  },
  data() {
    return {
      API_URL: API_URL,
      selectedItem: null,
      inputfile: null,
      contenido: this.value,
    };
  },
  methods: {
    onItemSelected(item) {
      this.selectedItem = item;
    },
    subirFoto: function () {
      this.inputfile.onchange = (e) => {
        let archivo = e.target.files[0];
        if (archivo.type.substr(0, 5) != "image") {
          alert("El archivo debe ser de tipo imagen");
          return;
        }
        if (archivo.size > 1024 * 1024 * 3) {
          alert("Solo se permiten archivos de hasta 3 MB");
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(archivo, "UTF-8");
        reader.onload = (readerEvent) => {
          let content = readerEvent.target.result;
          this.contenido = content;
        };
      };

      this.inputfile.click();
    },
    fotografiar: function () {
      this.$refs.foto.foto();
    },
    fotoTomada: function (foto) {
      this.contenido = foto;
      this.$refs.foto.detener();
      this.$refs.tomarfoto.hide();
    },
    tomarFoto: function () {
      this.login = {};
      this.$refs.tomarfoto.show();
    },
    quitarFoto: function() {
      this.contenido = null;
    },
    fotoRecortada: function () {
      this.contenido = this.$refs.cortador
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      this.$refs.recortarfoto.hide();
    },
    recortarFoto: function () {
      this.$refs.recortarfoto.show();
      this.$nextTick(() => {
        this.$refs.cortador.replace(this.contenido);
      });
    },
    imagen: function () {
      if (this.contenido != null && this.contenido.length > 120) {
        let pos = this.contenido.indexOf("base64,");
        let img = this.contenido;
        if (pos > 0) {
          img = img.substr(pos + 7);
        }
        return img;
      }
      return null;
    },
  },
  watch: {
    contenido() {
      this.$emit("input", this.contenido);
    },
    value(valor) {
      this.contenido = valor;
    },
  },
  created() {
    this.inputfile = document.createElement("input");
    this.inputfile.type = "file";
    this.inputfile.accept = "image/*";
  },
};
</script>

<style scoped>
button {
  background: lightsteelblue;
}
</style>