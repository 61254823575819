<template>
  <div id="app" class="container min-vh-100 d-flex flex-column">
    <link rel="shortcut icon" href="./assets/favicon.png" />
    <div class="row">
      <div class="container text-center col-sm-12 col-md-4">
        <img
          src="./assets/logo.png"
          align="center"
          class="img-fluid"
        />
      </div>
    </div>
    <br />
    <div>
      <b-navbar
        toggleable="md"
        variant="light"
        style="
          clear: both;
          border-top: 2px solid #48267d;
          border-bottom: 2px solid #48267d;
        "
      >
        <b-navbar-toggle target="nav-menu"></b-navbar-toggle>
        <b-collapse id="nav-menu" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/"><font-awesome-icon icon="home" /></b-nav-item>
            <b-nav-item to="/solicitud2" v-if="esAdministrador">Registrar solicitud</b-nav-item>
            <b-nav-item to="/solicitudcompleta" v-if="esAdministrador">Registrar solicitud amplia</b-nav-item>
            <b-nav-item to="/solicitudfederal" v-if="esAdministrador">Registrar solicitud federal</b-nav-item>
            <b-nav-item v-if="false" to="/solicitud">Registrar solicitud kiosko</b-nav-item>
            <b-nav-item v-if="sesionIniciada" to="/solicitudes">Seguimiento de solicitudes</b-nav-item>
            <b-nav-item-dropdown v-if="esAdministrador" text="Catálogos">
              <b-dropdown-item to="/areas">Dependencias</b-dropdown-item>
              <b-dropdown-item to="/usuarios" v-if="esAdministrador">Usuarios</b-dropdown-item>
              <b-dropdown-item to="/tiposolicitud">Tipos de solicitudes</b-dropdown-item>
              <b-dropdown-item to="/estatusolicitud">Estatus de solicitudes</b-dropdown-item>
              <b-dropdown-item to="/prioridadsolicitud">Prioridad de solicitudes</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown v-if="esAdministrador" text="Reportes">
              <b-dropdown-item to="/rareas">Por Dependencias</b-dropdown-item>
              <b-dropdown-item to="/restatus">Por Estatus</b-dropdown-item>
              <b-dropdown-item to="/rtipos">Por Tipos de Solicitud</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown v-if="esAdministrador" text="Gráficas">
              <b-dropdown-item to="/gatencionarea">De Atención</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="sesionIniciada" to="/salir">Salir</b-nav-item>
            <b-nav-item v-if="!sesionIniciada" to="/acceso">Acceso</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="text-right">Bienvenid@ {{ nombreCompleto }}</div>
    <div class="flex-grow-1">
      <router-view />
    </div>
    <br />
    <div class="text-right">
      <p style="font-size: 0.7rem">
        <a href="/aviso de privacidad.pdf" target="_blank"><b>AVISO DE PRIVACIDAD</b></a>
      </p>
      <b-button v-if="false" class="float-right" @click="checarSesion">
        Prueba
      </b-button>
    </div>
    <snackbar
      baseSize="5rem" 
      ref="mensaje"
      :holdTime="5000" style="z-index: 1050;"
      position="top-center"
    />
    <b-modal ref="aviso" v-bind:title="titulomsg">
      {{ avisomsg }}
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      avisomsg: "",
      titulomsg: "",
    };
  },
  methods: {
    error(mensaje) {
      this.$refs.mensaje.error(mensaje);
    },
    mensaje(mensaje) {
      this.$refs.mensaje.info(mensaje);
    },
    aviso(mensaje) {
      this.titulomsg = "Aviso";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    alerta(mensaje) {
      this.titulomsg = "Error";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    checarSesion() {
      this.$store.dispatch("restaurarSesion");
    },
  },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    nombreCompleto() {
      if (this.sesionIniciada) {
        let usuario = this.$store.getters.usuario;
        return usuario.nombre;
      }
      return "Invitado";
    },
    sesionIniciada() {
      return this.$store.getters.sesionIniciada;
    },
    esAdministrador() {
      return this.$store.getters.esAdministrador;
    },
  },
  mounted() {
    this.$store.dispatch("restaurarSesion");
  },
};
</script>
<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 0.9em;
}

#nav {
  padding: 30px;
}

#nav a,
a {
  font-weight: bold;
  color: #8c2054;
}

#nav a.router-link-exact-active {
  color: #8c2054;
}

.container {
  background-color: white;
  min-height: 100%;
}
h4 {
  font-size: 1.4rem;
  color: #992f5f;
  line-height: 2rem;
}
h5 {
  font-size: 1.3rem;
  color: #992f5f;
  line-height: 1.8rem;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.grupo {
  padding: 10px;
  border-radius: 5px;
  margin: 1rem 0;
  background-color: #f8f9faab;
  border: solid #f8f9fa;
  border-width: 0.2rem 0;
}
.errores {
  font-size: 0.75rem;
  color: red;
}
.dirty,
.dirty:focus {
  border-color: #5a5;
}

.error,
.error:focus {
  border-color: red;
}
</style>
