<template>
  <div>
    <h4>Estatus de solicitud</h4>
    <p class="text-justify">
      <b>Proporciona los datos para buscar tu solicitud.</b> Si deseas crear una solicitud da clic 
       <b-link to="/solicitud">aquí</b-link>.
    </p>

    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Teléfono</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="busqueda.tel"
          class="form-control"
          :class="estatus($v.busqueda.tel)"
        />
        <div class="errores" v-if="!$v.busqueda.tel.required">
          Requerido
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>CURP</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="busqueda.curp"
          class="form-control"
          :class="estatus($v.busqueda.curp)"
        />
        <div class="errores" v-if="!$v.busqueda.curp.required">Requerido</div>
      </div>
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="buscar">
          Buscar solicitud
        </button>
      </div>
    </div>
    <div v-show="buscando" >
      <h4>Solicitudes encontradas</h4>
      <div class="table-responsive">
        <table class="table table-condensed">
          <thead>
            <th>Nombre</th>
            <th></th>
            <th>Dias</th>
            <th>Estatus</th>
            <th>Prioridad</th>
            <th>Tipo solicitud</th>
            <th>Dependencia</th>
            <th>Fecha</th>
            <th></th>
          </thead>
          <tr v-for="solicitud in solicitudes" :key="solicitud.id_solicitud">
            <td>{{solicitud.paterno}} {{solicitud.materno}} {{solicitud.nombre}}</td>
            <td><b-icon icon="exclamation-circle-fill" :variant="solicitud.id_estatus == 7 ? 'success' : solicitud.dias > solicitud.diaslimite  ? 'danger' : 'warning'"></b-icon></td>
            <td>{{solicitud.dias}}</td>
            <td>{{solicitud.estatus}}</td>
            <td>{{solicitud.prioridad}}</td>
            <td>{{solicitud.tiposolicitud}}</td>
            <td>{{solicitud.area}}</td>
            <td>{{solicitud.fecha}}</td>
            <td>
              <a href="javascript:;" @click="abrirPdf(solicitud)"><font-awesome-icon size="lg" icon="file-pdf" /></a>&nbsp;
              <a href="javascript:;" @click="abrirDetalle(solicitud)"><font-awesome-icon size="lg" icon="edit" /></a>&nbsp;
              <a href="javascript:;" @click="abrirAvance(solicitud)"><font-awesome-icon size="lg" icon="info-circle" /></a>&nbsp;
            </td>
          </tr>
        </table>
      </div>
    </div>
    <b-modal size="xl" ref="detalle" title="Detalle de solicitud">
      <solicitud-ver :solicitud="solicitud" />
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal size="xl" ref="avance" title="Avance de solicitud">
      <solicitud-avance :solicitud="solicitud" />
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {PDF_URL} from '@/api'
import SolicitudVer from "@/views/SolicitudVer"
import SolicitudAvance from "@/views/SolicitudAvance"
import { requiredIf } from "vuelidate/lib/validators";


export default {
  name: "Estatus",
  components: {SolicitudVer, SolicitudAvance},
  data() {
    return {
      busqueda: { tel: '', curp: ''},
      solicitudes: [],
      buscando: false,
      solicitud: { },
    };
  },
  validations() {
    return {
      busqueda: {
        tel: {
          required: requiredIf(function (s) {
            return s.curp == "";
          }),
        },

        curp: {
          required: requiredIf(function (s) {
            return s.tel == "";
          }),
        },
      },
    };
  },
  methods: {
    buscar: function () {
      this.$v.busqueda.$touch();
      if (!this.$v.busqueda.$invalid) {
        this.buscando = true;
        api
          .buscarSolicitudes(this.busqueda)
          .then((r) => {
            this.solicitudes = r.data;
          })
          .catch(() => {
            this.solicitudes = [];
          });
      } else {
        this.$parent.error("Información incompleta");
        this.solicitudes = [];
        this.buscando = false;
      }
    },
    abrirPdf(s) {
      this.solicitud = s
      window.open(`${PDF_URL}/solicitud.php?id=${s.id_solicitud}`,'_blank');
    },
    abrirDetalle(s) {
      this.solicitud = s
      this.$refs.detalle.show();
    },
    abrirAvance(s) {
      this.solicitud = s
      this.$refs.avance.show();
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
};
</script>

