<template>
  <div>
    <h4>Reporte por dependencias</h4>
    <p class="text-justify">
      <b>Solicitudes atendidas por dependencia</b>
    </p>
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Fecha inicial</label>
        <b-form-datepicker
          v-model="busqueda.fechai"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-4">
        <label>Fecha final</label>
        <b-form-datepicker
          v-model="busqueda.fechaf"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="buscar">
          Buscar
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Dependencia</th>
          <th>Cantidad</th>
        </thead>
        <tr v-for="solicitud in solicitudes" :key="solicitud.id_solicitud">
          <td>{{ solicitud.area }}</td>
          <td>{{ solicitud.cantidad }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "Estatus",
  data() {
    return {
      solicitudes: [],
      busqueda: {
        fechai: null,
        fechaf: null,
      },
    };
  },
  methods: {
    buscar() {
      api.reporteAreas(this.busqueda).then((respuesta) => {
        this.solicitudes = respuesta.data;
      });
    },
  },
  mounted() {
    this.buscar();
  },
};
</script>

